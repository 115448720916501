/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Postcode from 'react-daum-postcode';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { completeOrder, resetCustomer } from '../redux/customerSlice';
import Order from '../components/Order';
import { PRODUCTS } from '../constants/products';
import { priceNumberToStirng } from '../util/price';

const container = css`
  width: 90%;
  max-width: 960px;
  margin: 40px auto;
`;

const productInfo = css`
  text-align: center;
  line-height: 1.5rem;
  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  img {
    max-width: 640px;
    height: auto;
  }
`;

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background: #fcfcfc;
  border-radius: 30px;
  box-shadow: 3px 3px 4px #dfdfdf;
`;

const typeName = css`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 16px;
`;

const optionContainer = css`
  margin-bottom: 40px;
`;

const optionWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  label {
    margin-right: 10px;
  }
`;

const inputNumber = css`
  width: 100px;
  padding: 0.5rem 1rem;
  border-radius: 35px;
  border: 1px solid #000;
  text-align: center;
  font-size: 16px;

  ::placeholder {
    text-align: center;
  }
`;

const text = css`
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin: 40px 0;
  background: #fff;
`;

const inputText = css`
  width: 80%;
  max-width: 500px;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  border: 1px solid #000;
  font-size: 16px;
  &::placeholder {
    font-weight: bold;
  }
`;

const postcodeWrapper = css`
  width: 50%;
`;

const button = css`
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  background: #000;
  color: #fff;
  margin-bottom: 40px;
  &:hover {
    background: #555;
  }
  &:active {
    background: #555;
  }
`;

const errorMessage = css`
  font-size: 0.9rem;
  color: #0000ff;
  margin: 10px 0;
`;

const desc = css`
  margin: 20px 0;
  text-align: center;
  strong {
    font-size: 1.5rem;
    color: red;
    font-weight: bold;
  }
`;

const infoMessage = css`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px #ddd;
  line-height: 1.5rem;
  margin-bottom: 20px;
`;

const productImg = css`
  width: 100%;
  height: 100;
`;

function Main() {
  const navigate = useNavigate();

  const [defaultAddress, setDefaultAddress] = useState(''); // 기본 도로명 주소
  const [visible, setVisible] = useState(false); // 주소 입력창 클릭하면 daum 주소 검색

  const [shortteeCool, setShortteeCool] = useState(new Map());
  const [longtee, setLongtee] = useState(new Map());
  const [windbreaker, setWindbreaker] = useState(new Map());
  const [hood, setHood] = useState(new Map());
  const [scarf, setScarf] = useState(new Map());

  const [orderInfo, setOrderInfo] = useState(null); // 이전 주문 내역
  const [addOrder, setAddOrder] = useState(false); // 추가 주문 여부

  //   const [uniformOption, setUniformOption] = useState([]);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address1: defaultAddress,
    },
  });

  const calcPrice = React.useCallback(() => {
    let shortteeQuan = 0;
    let longteeQuan = 0;
    let windbreakerQuan = 0;
    let hoodQuan = 0;
    let scarfQuan = 0;

    for (let shortteeCoolType of shortteeCool.values()) {
      if (shortteeCoolType.quantity !== '') {
        shortteeQuan += parseInt(shortteeCoolType.quantity);
      }
    }

    for (let longteeType of longtee.values()) {
      if (longteeType.quantity !== '') {
        longteeQuan += parseInt(longteeType.quantity);
      }
    }

    for (let windbreakerType of windbreaker.values()) {
      if (windbreakerType.quantity !== '') {
        windbreakerQuan += parseInt(windbreakerType.quantity);
      }
    }
    for (let hoodType of hood.values()) {
      if (hoodType.quantity !== '') {
        hoodQuan += parseInt(hoodType.quantity);
      }
    }

    for (let scarfType of scarf.values()) {
      if (scarfType.quantity !== '') {
        scarfQuan += parseInt(scarfType.quantity);
      }
    }

    return parseInt(
      shortteeQuan * PRODUCTS[2]['salePrice'] +
        longteeQuan * PRODUCTS[3]['salePrice'] +
        windbreakerQuan * PRODUCTS[0]['salePrice'] +
        hoodQuan * PRODUCTS[4]['salePrice'] +
        scarfQuan * PRODUCTS[7]['salePrice'] +
        4000,
    );
  }, [hood, longtee, scarf, shortteeCool, windbreaker]);

  // 사용자가 선택한 상품의 사이즈와 수량

  const addOption = React.useCallback(() => {
    // 타입별 사이즈 및 수량 선택
    let uniformOption = [];
    // 쿨 반팔티셔츠 옵션 선택
    for (let shortteeCoolType of shortteeCool.values()) {
      if (shortteeCoolType.quantity !== '') {
        uniformOption.push(shortteeCoolType);
      }
    }

    // 긴팔 티셔츠 옵션 선택
    for (let longteeType of longtee.values()) {
      if (longteeType.quantity !== '') {
        uniformOption.push(longteeType);
      }
    }

    // 바람막이 옵션 선택
    for (let windbreakerType of windbreaker.values()) {
      if (windbreakerType.quantity !== '') {
        uniformOption.push(windbreakerType);
      }
    }

    // 후드티 옵션 선택
    for (let hoodType of hood.values()) {
      if (hoodType.quantity !== '') {
        uniformOption.push(hoodType);
      }
    }

    // 스카프 옵션 선택
    for (let scarfType of scarf.values()) {
      if (scarfType.quantity !== '') {
        uniformOption.push(scarfType);
      }
    }
    return uniformOption;
  }, [hood, longtee, scarf, shortteeCool, windbreaker]);

  const resetForm = React.useCallback(
    data => {
      // 주문 정보 입력 초기화
      dispatch(
        completeOrder({
          name: data.name,
          phone: data.phone,
        }),
      );
      reset({
        name: '',
        nickname: '',
        depositName: '',
        phone: '',
        address: '',
      });
      setAddOrder(false);
      setDefaultAddress('');
      setShortteeCool(new Map());
      setLongtee(new Map());
      setWindbreaker(new Map());
      setHood(new Map());
      setScarf(new Map());

      navigate('/order');
    },
    [dispatch, navigate, reset],
  );

  const onSubmit = React.useCallback(
    async data => {
      const uniformOption = addOption();
      alert('주문이 마감되었습니다.');

      return false;

      if (uniformOption.length < 1) {
        alert('주문 정보를 확인해 주세요');
        return;
      }

      if (addOrder === false) {
        axios
          .get(
            url,
            {
              // 주문내역 확인
              params: {
                name: data.name,
                phone: data.phone,
              },
            },
            { withCredentials: true },
          )
          .then(res => {
            if (res.data.length > 0) {
              // 이전 주문이 있는 경우
              setOrderInfo(res.data);
              setAddOrder(true);
            } else {
              // 신규 주문
              axios
                .post(url, {
                  name: data.name,
                  nickname: data.nickName,
                  depositName: data.depositName,
                  phone: data.phone,
                  address: `${data.address1} ${data.address2}`,
                  userRemark: data.userRemark,
                  uniform: uniformOption,
                  duplicateYn: 'n',
                })
                .then(res => {
                  console.log(res);
                  window.alert('주문이 완료되었습니다.');
                  resetForm(data);
                })
                .catch(err => console.log(err));
            }
          })
          .catch(err => console.log(err));
      } else {
        // 추가 주문 건
        axios
          .post(
            url,
            {
              name: data.name,
              nickname: data.nickName,
              depositName: data.depositName,
              phone: data.phone,
              address: `${data.address1} ${data.address2}`,
              userRemark: data.userRemark,
              uniform: uniformOption,
              duplicateYn: 'y',
            },
            { withCredentials: true },
          )
          .then(res => {
            console.log(res);
            window.alert('추가 주문이 완료되었습니다.');
            resetForm(data);
          })
          .catch(err => console.log(err));
      }
    },
    [addOption, addOrder, resetForm],
  );

  const onAddressChange = e => {
    // 검색한 주소 input창에 보여주기
    const {
      target: { value },
    } = e;
    setDefaultAddress(value);
  };

  const handlePostcode = data => {
    // 주소 입력 창 클릭하면 daum 주소 검색 실행
    let fullAddress = `(${data.zonecode}) ${data.address}`;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setDefaultAddress(fullAddress);
    setValue('address1', fullAddress);
  };

  useEffect(() => {
    reset({
      name: '',
      nickname: '',
      depositName: '',
      phone: '',
      address: '',
    });
    dispatch(resetCustomer());
  }, []);

  return (
    <div css={container}>
      <h2 css={text}>상품 정보</h2>
      <div css={productInfo}>
        <p>
          💛가수님 명품교복 공동구매💛
          <br />
          🎊노랑이 명품 교복입고 룰루랄라
          <br />
          <br />
          ✨공연장으로 꼬꼬💨
          <br />
          ⭐이뿐이 노랑이⭐ 저두 정모 낑까주이소❗❗
          <br />
          <br />
          이번 명품교복은
          <br />
          🧸캐주얼한 후드 티와 후드 집업🧸
          <br />
          그리고
          <br />
          그토록 닻별님들이 원하시던
          <br />
          🌀바람막이🌀
          <br />
          👏👏👏같이입고 공연장 꼬꼬👏👏👏
        </p>
        <a href="../assets/images/psj_goods_v1.jpg">
          <img
            css={productImg}
            src="../assets/images/psj_goods_v1.jpg"
            alt="박서진 상품"
          />
        </a>
      </div>
      <form css={form} onSubmit={handleSubmit(onSubmit)}>
        <h2 css={text}>상품 선택</h2>

        {/* 쿨 반팔 티셔츠 주문 정보 */}
        <h3 css={typeName}>
          {`💛 ${PRODUCTS[2].nameKo} : ${priceNumberToStirng(
            PRODUCTS[2].salePrice,
          )}`}
        </h3>
        <div css={optionContainer}>
          {PRODUCTS[2]['size'].map(item => (
            <div css={optionWrapper} key={item}>
              <label>{item} 사이즈</label>
              <input
                css={inputNumber}
                type="number"
                // placeholder="갯수 입력"
                placeholder="품절"
                min={1}
                max={100}
                onChange={e => {
                  setShortteeCool(prev => {
                    return new Map([
                      ...prev,
                      [
                        item,
                        {
                          type: PRODUCTS[2].nameDb,
                          size: item,
                          quantity: e.target.value,
                        },
                      ],
                    ]);
                  });
                }}
                value={item.quantity}
                disabled={true}
              />
            </div>
          ))}
        </div>

        {/* 바람막이 주문 정보 */}
        <h3 css={typeName}>
          {`💛 ${PRODUCTS[0].nameKo} : ${priceNumberToStirng(
            PRODUCTS[0].salePrice,
          )}`}
        </h3>
        <div css={optionContainer}>
          {PRODUCTS[0]['size'].map(item => (
            <div css={optionWrapper} key={item}>
              <label>{item} 사이즈</label>
              <input
                css={inputNumber}
                type="number"
                // placeholder="갯수 입력"
                placeholder="품절"
                min={1}
                max={100}
                onChange={e => {
                  setWindbreaker(prev => {
                    return new Map([
                      ...prev,
                      [
                        item,
                        {
                          type: PRODUCTS[0].nameDb,
                          size: item,
                          quantity: e.target.value,
                        },
                      ],
                    ]);
                  });
                }}
                value={item.quantity}
                disabled={true}
              />
            </div>
          ))}
        </div>

        {/* 주문자 정보 */}
        <h2 css={text}>주문자 정보</h2>
        <input
          css={inputText}
          type="text"
          placeholder="주문자 이름 (한글, 영문 대소문자, 숫자만 입력)"
          {...register('name', {
            required: true,
            minLength: 2,
            maxLength: 10,
            // pattern: /^[0-9a-zA-Z가-힣]*$/'
          })}
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.name?.type === 'required' && '주문자명을 입력해주세요.'}
          {errors.name?.type === 'pattern' &&
            '이름은 한글, 영문 대소문자, 숫자만 입력해주세요.'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="닉네임"
          {...register('nickName', {
            required: true,
            minLength: 2,
            maxLength: 20,
          })}
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.nickName?.type === 'required' && '닉네임을 입력해주세요.'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="송금자 이름 (한글, 영문 대소문자, 숫자만 입력)"
          {...register('depositName', {
            required: true,
            minLength: 2,
            maxLength: 10,
            // pattern: /^[0-9a-zA-Z가-힣]*$/
          })}
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.depositName?.type === 'required' &&
            '송금자명을 입력해주세요.'}
          {errors.depositName?.type === 'pattern' &&
            '올바른 송금자명을 입력해주세요.'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="휴대폰 번호 ( 01012345678 )"
          {...register('phone', {
            required: true,
            pattern: /^[0-9-]{9,14}$/,
            // pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
          })}
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.phone?.type === 'required' && '휴대전화 번호를 입력해주세요.'}
          {errors.phone?.type === 'pattern' &&
            '번호는 숫자나 -만으로 다시 입력해주세요. ex)01012341234'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="주소"
          {...register('address1', {
            required: true,
          })}
          value={defaultAddress}
          onChange={onAddressChange}
          onClick={() => {
            setVisible(true);
          }}
          required
          readOnly
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.address1?.type === 'required' &&
            defaultAddress === '' &&
            '도로명 주소를 입력해주세요.'}
        </div>
        {visible && (
          <div css={postcodeWrapper}>
            <Postcode onComplete={handlePostcode} />
          </div>
        )}
        <input
          css={inputText}
          type="text"
          placeholder="상세주소"
          {...register('address2', {
            required: true,
          })}
          disabled={false}
        />
        <div css={errorMessage}>
          {errors.address2?.type === 'required' && '주소를 입력해주세요.'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="주문자 요청사항"
          {...register('userRemark', {
            // required: true
          })}
          disabled={false}
        />
        <div css={desc}>
          <p>
            총 주문금액 :{' '}
            {(calcPrice() - 4000)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
            원
          </p>
          &nbsp;
          <p>+</p>
          &nbsp;
          <p>배송비 : 4,000 원</p>
          &nbsp;
          <p>
            최종 결제금액 :{' '}
            <strong>
              {calcPrice()
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
              원
            </strong>
          </p>
        </div>
        <div css={productInfo}>
          <div css={infoMessage}>
            ⭐ 입금 확인 후 상품 배송해 드립니다.
            <br />
            입금 계좌 : 농협은행 301-0227-0959-11
            <br />
            예금주 : 박효빈
          </div>
        </div>
        {orderInfo &&
          orderInfo.map((item, index) => (
            <Order orderInfo={item} key={index} />
          ))}
        <button css={button}>{orderInfo ? '추가주문하기' : '주문하기'}</button>
      </form>
    </div>
  );
}

export default Main;

const url = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order`;
