/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { convertNameToNameKo } from '../util/parse';

const infoContainer = css`
  width: 90%;
  max-width: 960px;
  margin: 40px auto;
`;

const infoWrapper = css`
  width: 90%;
  margin: 0 auto;
  background: #fafafa;
  box-shadow: 4px 4px 4px #ddd;
  border-radius: 20px;
  padding: 1rem 2rem;
`;

const content = css`
  margin: 20px 0; ;
`;

const title = css`
  font-weight: bold;
  margin-bottom: 10px;
`;

const text = css`
  line-height: 1.5rem;
  strong {
    font-weight: bold;
  }
  span {
    font-weight: bold;
    font-size: 1.2rem;
    text-shadow: 2px 2px 2px #ddd;
  }
`;

const desc = css`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px #ddd;
  line-height: 1.5rem;
  font-size: 1.1rem;
`;

const orderComplete = css`
  font-weight: bold;
  margin-bottom: 40px;
  font-size: 1.2rem;
`;

function Order({ orderInfo }) {
  return (
    <>
      <div css={infoContainer}>
        <div css={infoWrapper}>
          <div css={content}>
            <div css={orderComplete}>
              {' '}
              - 아래 내역은 주문완료된 내용입니다. 중복주문이 아닌지
              확인해주세요!
            </div>
            <div css={title}>- 주문 내역</div>
            {orderInfo.uniform.length > 0 &&
              orderInfo.uniform.map((item, i) => (
                <span key={i}>
                  <p css={text}>{convertNameToNameKo(item.type)}</p>
                  <p css={text}>
                    {item.size}사이즈 - {item.quantity}개
                  </p>
                </span>
              ))}
          </div>
          <div css={content}>
            <div css={title}>- 주문 정보</div>
            <p css={text}>
              <strong>{orderInfo.name}</strong> (닉네임: {orderInfo.nickname})
            </p>
            <p css={text}>
              총 주문 금액:{' '}
              {orderInfo.totalPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              원
            </p>
            <p css={text}>송금자명: {orderInfo.depositName}</p>
            <p css={text}>요청사항: {orderInfo.userRemark}</p>
            <p css={text}>
              송금확인:{' '}
              <span>
                {orderInfo.depositYn === 'y'
                  ? '입금 완료'
                  : '미입금 (실시간 입금 확인 됩니다.)'}
              </span>
            </p>
            <p css={text}>
              배송정보:
              <strong>
                {orderInfo.releaseYn === 'y' && orderInfo.trackingNumber !== ''
                  ? ` 출고 되었습니다. ( 운송장 번호 ${orderInfo.trackingNumber} )`
                  : orderInfo.trackingNumber !== ''
                  ? ' 출고 준비 중입니다.'
                  : ' 입금 확인 후 상품 배송해 드립니다.'}
              </strong>
            </p>
          </div>
          <div css={content}>
            <div css={title}>- 주문자 정보</div>
            <p css={text}>이름: {orderInfo.name}</p>
            <p css={text}>연락처: {orderInfo.phone}</p>
            <p css={text}>주소: {orderInfo.address}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Order;
