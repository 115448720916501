/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from '@emotion/react';
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../redux/userSlice";

const form = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const inputText = css`
    width: 50%;
    max-width: 480px;
    padding: 0.7rem;
    border-radius: 30px;
    border: 1px solid #000;
    margin-bottom: 10px;
    &::placeholder {
        font-weight: bold;
    }
`;

const button = css`
    border: none;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    background: #000;
    color: #fff;
    &:hover {
        background: #555;
    }
    &:active {
        background: #555;
    }
`;

function Login() {

    const url = `${process.env.NODE_ENV === "production" ? window.location.origin + "/" : process.env.REACT_APP_API_URL}api/member/login`;

    const dispatch = useDispatch();

    const [loginValue, setLoginValue] = useState();

    const onChange = (e) => {
        const { name, value } = e.target;
        setLoginValue({
            ...loginValue,
            [name]: value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        axios.post(url, loginValue)
        .then(res => {
            // console.log(res.headers);
            dispatch(login({
                auth: res.headers.authorization
            }));
            res.data !== '' && window.alert(res.data);
        }).catch(err => console.log(err));
        setLoginValue();
    }
 
    return ( 
        <form css={form} onSubmit={onSubmit}>
            <input css={inputText} type='text' name='username' placeholder='아이디' onChange={onChange} />
            <input css={inputText} type='password' name='password' placeholder='패스워드' onChange={onChange} />
            <button css={button}>로그인</button>
        </form>
     );
}

export default Login;