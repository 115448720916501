import { combineReducers, configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import userSlice from './userSlice';
import customerSlice from './customerSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['customer']
};

const rootReducer = combineReducers({
    user: userSlice,
    customer: customerSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});

export const persistor = persistStore(store);
export default store;