export const PRODUCTS = [
  {
    id: 0,
    name: 'windbreaker',
    nameKo: '바람막이',
    nameDb: 'windbreaker',
    salePrice: 40000,
    supplyPrice: 36000,
    size: [85, 90, 95, 100, 105, 110, 115],
  },
  {
    id: 1,
    name: 'shorttee',
    nameKo: '반팔티',
    nameDb: 'shorttee',
    salePrice: 0,
    supplyPrice: 0,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 2,
    name: 'shortteeCool',
    nameKo: '쿨반팔티',
    nameDb: 'shorttee_cool',
    salePrice: 28000,
    supplyPrice: 26000,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 3,
    name: 'longtee',
    nameKo: '긴팔티',
    nameDb: 'longtee',
    salePrice: 29000,
    supplyPrice: 27000,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 4,
    name: 'hood',
    nameKo: '후드티',
    nameDb: 'hood',
    salePrice: 38000,
    supplyPrice: 34000,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 5,
    name: 'hoodGimo',
    nameKo: '후드티(기모)',
    nameDb: 'hood_gimo',
    salePrice: 38000,
    supplyPrice: 34000,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 6,
    name: 'hoodzipup',
    nameKo: '후드집업',
    nameDb: 'hoodzipup',
    salePrice: 0,
    supplyPrice: 0,
    size: [85, 90, 95, 100, 105, 110],
  },
  {
    id: 7,
    name: 'scarf',
    nameKo: '목수건 1세트 (2개씩)',
    nameDb: 'scarf',
    salePrice: 10000,
    supplyPrice: 10000,
    size: ['FREE'],
  },
];
