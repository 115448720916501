/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../../components/Login';
import OrderList from '../../components/OrderList';
import { resetCustomer } from '../../redux/customerSlice';

const container = css`
  width: 90%;
  max-width: 1920px;
  margin: 40px auto;
  background: #fcfcfc;
`;

function Admin() {
  const user = useSelector(state => state.user); // 관리자 로그인 정보
  const dispatch = useDispatch();
  // console.log(user);

  useEffect(() => {
    dispatch(resetCustomer());
  }, []);

  return (
    <div css={container}>{user.auth === '' ? <Login /> : <OrderList />}</div>
  );
}

export default Admin;
