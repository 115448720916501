/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import axios from 'axios';
import { PRODUCTS } from '../constants/products';
import { convertNameToNameKo } from '../util/parse';

function OrderList() {
  const [info, setInfo] = useState(new Map()); // 구매 내역 목록
  const [inputValue, setInputValue] = useState(''); // 검색창 입력값
  const [list, setList] = useState('all'); // 목록 선택하여 보기
  const [checklist, setChecklist] = useState([]); // 선택한 주문 내역
  const [file, setFile] = useState(null); // 업로드할 파일

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth =
    today.getMonth() >= 9
      ? `${today.getMonth() + 1}`
      : `0${today.getMonth() + 1}`;
  const todayDate =
    today.getDate() > 9 ? `${today.getDate()}` : `0${today.getDate()}`;
  const date = `${todayYear}${todayMonth}${todayDate}`;

  const getList = () => {
    // 전체 목록 가져오기
    axios
      .get(url)
      .then(res => {
        setInfo(
          res.data.reduce(
            (prev, curr) =>
              prev.set(curr.orderId, {
                ...curr,
                trackingNumber: curr.trackingNumber || '',
                remark: curr.remark || '',
                changed: false,
              }),
            new Map(),
          ),
        );
      })
      .catch(err => console.log(err));
  };

  const scrollToTop = () => {
    // 맨 위로 스크롤
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleDelete = () => {
    // 주문 삭제
    if (window.confirm('선택한 주문을 정말 삭제하시겠습니까?')) {
      const temp = checklist.map(item => ({ orderId: item }));
      // console.log(temp);
      axios
        .delete(orderUrl, {
          data: temp,
        })
        .then(res => {
          getList();
          window.location.reload();
        })
        .catch(err => console.log(err));
    }
  };

  const onCheckedList = (checked, id) => {
    // 주문별 선택
    if (checked) {
      setChecklist([...checklist, id]);
    } else if (!checked) {
      setChecklist(checklist.filter(el => el !== id));
    }
  };

  const onChecklistAll = checked => {
    // 전체 선택
    if (checked) {
      let allList = [];
      for (let item of info.values()) {
        item.trackingNumber === '' && allList.push(item.orderId);
      }
      setChecklist(allList);
    } else {
      setChecklist([]);
    }
  };

  const uploadFile = e => {
    // 업로드할 파일 추가
    const data = e.target.files[0];
    setFile(data);
  };

  const submitFile = e => {
    // 엑셀 파일 업로드
    e.preventDefault();

    if (file) {
      const data = new FormData();
      data.append('file', file);
      axios
        .post(uploadUrl, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          res.status === 200 && window.location.reload();
        })
        .catch(err => console.log(err));
    } else {
      window.alert('업로드할 파일을 추가해주세요.');
    }
    setFile(null);
  };

  const createTrackingList = () => {
    // 운송장 리스트 excel 생성
    const temp = checklist.map(item => ({ orderId: item }));
    // console.log(temp);

    axios
      .post(trackingNumDownUrl, temp)
      .then(res => {
        // console.log(res);
        if (res.data.result === 'true') {
          axios
            .get(trackingNumDownUrl, { responseType: 'arraybuffer' })
            .then(res => {
              // console.log(res);
              const file = new Blob([res.data], {
                type: 'application/vnd.ms-excel',
              });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(file);
              link.download = `운송장_리스트_${date}.xls`;
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));
  };

  const checkStatus = (order, str) => {
    // 입금 및 출고 확인 체크
    if (str === 'deposit') {
      // 입금 확인
      setInfo(
        prev =>
          new Map([
            ...prev,
            [
              order.orderId,
              {
                ...order,
                depositYn:
                  order.depositYn === 'n'
                    ? 'y'
                    : order.depositYn === 'y'
                    ? 'n'
                    : order.depositYn,
                orderId: order.orderId,
                changed: true,
              },
            ],
          ]),
      );
    } else if (str === 'release') {
      // 출고 확인
      setInfo(
        prev =>
          new Map([
            ...prev,
            [
              order.orderId,
              {
                ...order,
                releaseYn:
                  order.releaseYn === 'n'
                    ? 'y'
                    : order.releaseYn === 'y'
                    ? 'n'
                    : order.releaseYn,
                orderId: order.orderId,
                changed: true,
              },
            ],
          ]),
      );
    }
  };

  const handleFormValue = (order, key, value) => {
    // 비고란 수정
    setInfo(
      prev =>
        new Map([
          ...prev,
          [
            order.orderId,
            {
              ...order,
              [key]: value,
              changed: true,
            },
          ],
        ]),
    );
  };

  const onChange = e => {
    // 검색창 입력
    const {
      target: { value },
    } = e;
    setInputValue(value);
  };

  const handleSearch = e => {
    // 주문자, 닉네임, 송금자로 검색
    e.preventDefault();
    inputValue !== '' &&
      axios
        .get(`${searchUrl}/${inputValue}`)
        .then(res => {
          // console.log(res.data);
          setInfo(
            res.data.reduce(
              (prev, curr) =>
                prev.set(curr.orderId, {
                  ...curr,
                  trackingNumber: curr.trackingNumber || '',
                  remark: curr.remark || '',
                  changed: false,
                }),
              new Map(),
            ),
          );
        })
        .catch(err => console.log(err));
    setInputValue('');
    setList('');
  };

  const handleSave = () => {
    // 입금 확인 내역 저장
    const temp = Array.from(info.values())
      .filter(el => el.changed === true)
      .reduce(
        (prev, curr) => [
          ...prev,
          {
            orderId: curr.orderId,
            depositYn: curr.depositYn,
            releaseYn: curr.releaseYn,
            remark: curr.remark,
          },
        ],
        [],
      );
    // console.log(temp);

    axios
      .put(url, temp)
      .then(res => {
        window.alert('저장 완료되었습니다.');
      })
      .catch(err => console.log(err));
  };

  const handleList = str => {
    // 주문 내역 구분
    let listUrl = url;
    switch (str) {
      case 'all':
        listUrl = url;
        break;
      case 'depositY':
        listUrl = `${depositCheckUrl}/y`;
        break;
      case 'depositN':
        listUrl = `${depositCheckUrl}/n`;
        break;
      case 'releaseY':
        listUrl = `${releaseCheckUrl}/y`;
        break;
      case 'releaseN':
        listUrl = `${releaseCheckUrl}/n`;
        break;
      case 'duplicate':
        listUrl = duplicateListUrl;
        break;
      default:
        listUrl = url;
    }

    axios
      .get(listUrl)
      .then(res => {
        setInfo(
          res.data.reduce(
            (prev, curr) =>
              prev.set(curr.orderId, {
                ...curr,
                trackingNumber: curr.trackingNumber || '',
                remark: curr.remark || '',
                changed: false,
              }),
            new Map(),
          ),
        );
      })
      .catch(err => console.log(err));
    setList(str);
  };

  useEffect(() => {
    getList();
  }, [url]);

  return (
    <div css={container}>
      <div css={scrollTop} onClick={scrollToTop}>
        ▵
      </div>
      <div css={controller}>
        <div css={buttonWrapper}>
          <form onSubmit={submitFile}>
            <label css={label}>
              {file ? file.name : '+ Excel 파일'}
              <input
                css={inputFile}
                type="file"
                accept="application/vnd.ms-excel"
                onChange={e => uploadFile(e)}
              />
            </label>
            <button css={optionButton}>송장 업로드</button>
          </form>
          <button
            css={optionButton}
            type="button"
            onClick={() => createTrackingList()}
          >
            운송장 리스트 생성
          </button>
        </div>
        <div css={buttonWrapper}>
          <a href={excelDownUrl}>
            <button
              css={optionButton}
              type="button"
              onChange={e => uploadFile(e)}
            >
              전체 목록 Excel 다운로드
            </button>
          </a>
          <a href={excelDownUrl1}>
            <button css={optionButton} type="button">
              입금 Excel 다운로드
            </button>
          </a>
          <a href={excelDownUrl2}>
            <button css={optionButton} type="button">
              미입금 Excel 다운로드
            </button>
          </a>
          <a href={excelDownUrl3}>
            <button css={optionButton} type="button">
              출고 Excel 다운로드
            </button>
          </a>
          <a href={excelDownUrl4}>
            <button css={optionButton} type="button">
              미출고 Excel 다운로드
            </button>
          </a>
          <a href={excelDownUrl5}>
            <button css={optionButton} type="button">
              중복 Excel 다운로드
            </button>
          </a>
          <button css={button} onClick={handleSave}>
            저 장
          </button>
        </div>
        <form css={form} onSubmit={handleSearch}>
          <input
            css={inputText}
            type="text"
            placeholder="주문자명 또는 닉네임 또는 송금자명..."
            value={inputValue || ''}
            onChange={onChange}
          />
          <button css={button}>검색</button>
        </form>
        <nav css={nav}>
          <ul css={ul}>
            <li
              css={list === 'all' && active}
              onClick={() => handleList('all')}
            >
              전체
            </li>
            <li
              css={list === 'depositY' && active}
              onClick={() => handleList('depositY')}
            >
              입금
            </li>
            <li
              css={list === 'depositN' && active}
              onClick={() => handleList('depositN')}
            >
              미입금
            </li>
            <li
              css={list === 'releaseY' && active}
              onClick={() => handleList('releaseY')}
            >
              출고
            </li>
            <li
              css={list === 'releaseN' && active}
              onClick={() => handleList('releaseN')}
            >
              미출고
            </li>
            <li
              css={list === 'duplicate' && active}
              onClick={() => handleList('duplicate')}
            >
              중복주문
            </li>
          </ul>
          <button css={deleteButton} onClick={handleDelete}>
            선택한 주문 취소
          </button>
        </nav>
        <div css={desc}>
          * 좌우로 스크롤. 입금 확인 체크 및 비고란 입력 또는 출고 체크 후 저장.
          주문 내역 선택 후 운송장 리스트 생성. Excel 파일 송장 업로드.
        </div>
      </div>
      <div css={listWrapper}>
        <table css={table}>
          <thead>
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={checklist.length === 0 ? false : true}
                  onChange={e => onChecklistAll(e.target.checked)}
                />
              </td>
              <td>번호</td>
              <td>주문인</td>
              <td>닉네임</td>
              <td>송금자</td>
              <td>총 주문액</td>
              <td className="black">입금 확인</td>
              <td className="black">고객 요청 사항</td>
              <td className="black">비고</td>
              <td className="black">출고 확인</td>
              <td className="black">송장 번호</td>
              <td>주문 내역</td>
              <td>연락처</td>
              <td>주소</td>
              <td>주문일</td>
            </tr>
          </thead>
          <tbody>
            {info.size > 0 &&
              Array.from(info.values()).map((order, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        checklist.includes(order.orderId) &&
                        order.trackingNumber === ''
                          ? true
                          : false
                      }
                      onChange={e => {
                        order.trackingNumber === '' &&
                          onCheckedList(e.target.checked, order.orderId);
                      }}
                      disabled={
                        order.releaseYn === 'y' || order.trackingNumber != ''
                      }
                    />
                  </td>
                  <td>{order.orderId}</td>
                  <td>{order.name}</td>
                  <td>{order.nickname}</td>
                  <td>{order.depositName}</td>
                  <td>
                    {order.totalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    원
                  </td>
                  <td className="edit">
                    <input
                      type="checkbox"
                      checked={order.depositYn === 'y'}
                      onChange={() => {
                        checkStatus(order, 'deposit');
                      }}
                      disabled={order.releaseYn === 'y'}
                    />
                  </td>

                  <td className="edit">{order.userRemark}</td>
                  <td className="edit">
                    <input
                      css={formInput}
                      type="text"
                      value={order.remark}
                      name="remark"
                      onChange={e => {
                        handleFormValue(order, 'remark', e.target.value);
                      }}
                    />
                  </td>
                  <td className="edit">
                    <input
                      type="checkbox"
                      checked={
                        order.depositYn === 'y' && order.releaseYn === 'y'
                      }
                      onChange={() => {
                        order.depositYn === 'y'
                          ? checkStatus(order, 'release')
                          : window.alert(
                              '입금 확인 완료 후 출고 확인 해주세요.',
                            );
                      }}
                    />
                  </td>
                  <td>{order.trackingNumber}</td>

                  <td>
                    {order.uniform.map((item, i) => (
                      <p key={i}>
                        {convertNameToNameKo(item.type)} {item?.size ?? ''} (
                        {item.quantity}개)
                      </p>
                    ))}
                  </td>
                  <td>{order.phone}</td>
                  <td>{order.address}</td>
                  <td>{order.createdAt}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrderList;

const url = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/orders`;
const orderUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order`;
const depositCheckUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/depositcheck`;
const releaseCheckUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/releasecheck`;
const duplicateListUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/duplicatecheck`;
const searchUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/search`;
const excelDownUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/all/o`;
const excelDownUrl1 = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/deposit/y`;
const excelDownUrl2 = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/deposit/n`;
const excelDownUrl3 = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/release/y`;
const excelDownUrl4 = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/release/n`;
const excelDownUrl5 = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/exceldown/duplicate/o`;
const uploadUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/trackingnumberexcelupload`;
const trackingNumDownUrl = `${
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/'
    : process.env.REACT_APP_API_URL
}api/order/trackingnumberexceldownload`;

const container = css`
  width: 100%;
  margin: 40px auto;
`;

const scrollTop = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #444;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  cursor: pointer;
  z-index: 2;
`;

const nav = css`
  display: flex;
  justify-content: space-between;
`;

const listWrapper = css`
  width: 100%;
  margin: 20px 0;
  height: 70vh;
  overflow: auto;
`;

const ul = css`
  display: flex;
  li {
    margin: 0 10px 20px 0;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid #444;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
`;

const active = css`
  font-weight: bold;
`;

const table = css`
  width: max-content;
  border: 1px solid #555;
  thead {
    font-weight: bold;
    text-align: center;
    position: sticky;
    z-index: 1;
    top: 0;
    td {
      background-color: #fff;
      &.black {
        background: #555;
        color: #fff;
      }
    }
  }
  tr,
  td {
    border: 1px solid #bbb;
    padding: 0.4rem;
    vertical-align: middle;
  }
  td {
    /* white-space: nowrap; */
    line-height: 1.4rem;
  }
  .edit {
    text-align: center;
  }
`;

const editTd = css`
  background: #555;
  color: #fff;
`;

const formInput = css`
  border: none;
  padding: 0.4rem 0;
  &:disabled {
    background: #fff;
  }
  &:focus {
    background: #fff;
    outline: none;
  }
`;

const controller = css`
  width: 100%;
  background: #fff;
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  border-bottom: #eee solid 1px;
`;

const buttonWrapper = css`
  background-color: white;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

const optionButton = css`
  border: 1px solid #555;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  background: #555;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  margin-right: 8px;
  &:hover {
    background: #fff;
    color: #555;
  }
  &:active {
    background: #fff;
    color: #555;
  }
`;

const label = css`
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #999;
  color: #777;
  padding: 0.4rem;
  font-size: 0.9rem;
`;

const inputFile = css`
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
`;

const form = css`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 10px 0 20px 0;
`;

const inputText = css`
  width: 50%;
  padding: 0.5rem;
  border-radius: 30px;
  border: 1px solid #000;
  margin-right: 10px;
  &::placeholder {
    font-weight: bold;
  }
`;

const button = css`
  border: none;
  border-radius: 30px;
  padding: 0.5rem 2rem;
  font-weight: bold;
  background: #000;
  color: #fff;
  &:hover {
    background: #555;
  }
  &:active {
    background: #555;
  }
`;

const deleteButton = css`
  font-size: 0.9rem;
  width: 160px;
  height: 32px;
  border-radius: 30px;
  background: #fff;
  color: #444;
  border: 1px solid #888;
  cursor: pointer;
  &:hover {
    background: #888;
    color: #fff;
  }
`;

const desc = css`
  margin-bottom: 8px;
  color: #888;
  font-size: 0.8rem;
`;
