/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { resetCustomer } from '../../redux/customerSlice';
import Order from '../../components/Order';

const container = css`
  width: 90%;
  max-width: 960px;
  margin: 40px auto;
`;

const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const inputText = css`
  width: 50%;
  padding: 0.7rem;
  border-radius: 30px;
  border: 1px solid #000;
  margin-bottom: 10px;
  &::placeholder {
    font-weight: bold;
  }
`;

const errorMessage = css`
  font-size: 0.9rem;
  color: #888;
  margin: 0 0 10px 0;
`;

const button = css`
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  background: #000;
  color: #fff;
  &:hover {
    background: #555;
  }
  &:active {
    background: #555;
  }
`;

const infoContainer = css`
  width: 90%;
  max-width: 960px;
  margin: 40px auto;
`;

const infoWrapper = css`
  width: 800px;
  margin: 0 auto;
  background: #fafafa;
  box-shadow: 4px 4px 4px #ddd;
  border-radius: 20px;
  padding: 1rem 2rem;
`;

const text = css`
  line-height: 1.5rem;
  strong {
    font-weight: bold;
  }
  span {
    font-weight: bold;
    font-size: 1.2rem;
    text-shadow: 2px 2px 2px #ddd;
  }
`;

const content = css`
  width: 80%;
  margin: 20px auto;
`;

const desc = css`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3px 3px 3px #ddd;
  line-height: 1.5rem;
  font-size: 1.1rem;
`;

function OrderInfo() {
  const url = `${
    process.env.NODE_ENV === 'production'
      ? window.location.origin + '/'
      : process.env.REACT_APP_API_URL
  }api/order`;

  const [customer, setCustomer] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [errMessage, setErrMessage] = useState(null);

  const newCustomer = useSelector(state => state.customer);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    // console.log(data);
    setErrMessage('');
    setCustomer({
      ...customer,
      name: data.name,
      phone: data.phone,
    });
    axios
      .get(url, {
        params: {
          name: data.name,
          phone: data.phone,
        },
      })
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          setOrderInfo(res.data);
        }
        if (res.data === '') {
          setErrMessage('구매 내역이 없습니다.');
          setOrderInfo(null);
        }
      })
      .catch(err => {
        console.log(err);
      });
    reset({
      name: '',
      phone: '',
    });
    setCustomer(null);
    dispatch(resetCustomer());
  };

  useEffect(() => {
    if (newCustomer.name !== '' && newCustomer.phone !== '') {
      axios
        .get(url, {
          params: {
            name: newCustomer.name,
            phone: newCustomer.phone,
          },
        })
        .then(res => {
          console.log(res);
          if (res.status === 200) {
            setOrderInfo(res.data);
          } else if (res.data === '') {
            setErrMessage('구매 내역이 없습니다.');
            setOrderInfo(null);
          }
        })
        .catch(err => console.log(err));
    }
  }, []);

  return (
    <div css={container}>
      <form css={form} onSubmit={handleSubmit(onSubmit)}>
        <input
          css={inputText}
          type="text"
          placeholder="주문자 이름"
          {...register('name', {
            required: true,
            minLength: 2,
            maxLength: 10,
            pattern: /^[0-9a-zA-Z가-힣]*$/,
          })}
        />
        <div css={errorMessage}>
          {errors.name?.type === 'required' && '주문자명을 입력해주세요.'}
          {errors.name?.type === 'pattern' && '올바른 이름을 입력해주세요.'}
        </div>
        <input
          css={inputText}
          type="text"
          placeholder="휴대전화 번호 (01012345678)"
          {...register('phone', {
            required: true,
            pattern: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
          })}
        />
        <div css={errorMessage}>
          {errors.phone?.type === 'required' && '휴대전화 번호를 입력해주세요.'}
          {errors.phone?.type === 'pattern' &&
            '번호를 올바른 양식으로 입력해주세요.'}
        </div>
        <button css={button}>주문 확인</button>
      </form>
      {orderInfo &&
        orderInfo.map((item, index) => <Order orderInfo={item} key={index} />)}
      {orderInfo && (
        <div css={content}>
          <div css={desc}>
            ⭐ 입금 확인 후 상품 배송해 드립니다.
            <br />
            입금 계좌 : 농협은행 301-0227-0959-11
            <br />
            예금주 : 박효빈
          </div>
        </div>
      )}
      {errMessage && (
        <div css={infoContainer}>
          <div css={infoWrapper}>
            <p css={text}>{errMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderInfo;
