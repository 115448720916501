/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Admin from './pages/admin/Admin';
import Main from './pages/Main';
import OrderInfo from './pages/order/OrderInfo';
import reset from './style/reset';

function App() {
  return (
    <>
      <Global styles={reset} />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/order' element={<OrderInfo />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
