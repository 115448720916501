/**
 * 가격 Number 을 Ko String 값으로 변환
 * @param {*} num
 * @returns string
 */
export const priceNumberToStirng = num => {
  return `${numberWithCommas(num)}원`;
};

/**
 * 3번째 숫자마다 쉼표 찍는 함수
 * @param x : string | number
 * @returns string
 */
export const numberWithCommas = x => {
  if (x === 0 || x === '0') {
    return '0';
  }
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};
