import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        name: '',
        phone: ''
    },
    reducers: {
        completeOrder: (state, action) => {
            state.name = action.payload.name;
            state.phone = action.payload.phone;
            return state;
        },
        resetCustomer: (state) => {
            state.name = '';
            state.phone = '';
            localStorage.removeItem('customer');
            return state;
        }
    }
});

export const { completeOrder, resetCustomer } = customerSlice.actions;
export default customerSlice.reducer;