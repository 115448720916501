import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        auth: ''
    },
    reducers: {
        login: (state, action) => {
            state.auth = action.payload.auth;
            return state;
        },
        logout: (state) => {
            state.auth = '';
            localStorage.removeItem('user');
            // storage.removeItem('persist:root');
            return state;
        }
    }
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;