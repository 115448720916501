import { PRODUCTS } from '../constants/products';
/**
 * 상품의 DB 상품 타입을 한글 상품명으로 변환
 * @param {string} nameDb
 * @returns {string} nameKo
 */
export const convertNameToNameKo = nameDb => {
  const result = PRODUCTS.filter(product => product.nameDb === nameDb);
  if (result.length === 0) {
    return '';
  }
  return result[0]['nameKo'];
};
