/** @jsxImportSource @emotion/react */
import React from "react";
import { css, jsx } from '@emotion/react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import axios from "axios";
import { resetCustomer } from "../redux/customerSlice";

const header = css`
    width: 100vw;
    height: 110px;
    border-bottom: 1px solid #eee;
    position: relative;
`;

const title = css`
    width: 100%;
    text-align: center;
    margin: 20px 0;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
`;

const nav = css`
    width: 50%;
    height: 100%;
    margin: 0 auto;
`;

const ul = css`
    width: 100%;
    height: 100%;;
    display: flex;
    justify-content: space-between;
    a {
        &:hover {
            font-weight: 800;
            text-decoration: underline;
        }
    }
`;

const li = css`
    font-weight: 800;
`;

const localNav = css`
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    color: #888;
    font-size: 0.9rem;
    display: flex;
    div {
        cursor: pointer;
    }
`;

function Header() {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    }

    return ( 
        <header css={header}>
            <h1 css={title} onClick={() => { navigate('/') }}>공동 구매</h1>
            <nav css={nav}>
                <ul css={ul}>
                    <li css={location.pathname === '/' && li}><Link to='/'>주문하기</Link></li>
                    <li css={location.pathname === '/order' && li}><Link to='/order'>주문확인</Link></li>
                </ul>
            </nav>
            <nav css={localNav}>
                {
                    user.auth === ''
                    ? <div onClick={() => { navigate('/admin') }}>관리자 로그인</div>
                    : <>
                        <div onClick={() => { navigate('/admin') }}>관리자 화면</div>&nbsp;/&nbsp;
                        <div onClick={handleLogout}>로그아웃</div>
                    </>
                }
            </nav>
        </header>
     );
}

export default Header;